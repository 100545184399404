import { Component, HostBinding, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import Bugsnag from '@bugsnag/js';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@softbrik/data/services';

export function confirmedValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];

    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmed: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

@Component({
  selector: 'softbrik-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  @HostBinding('class.page') isPage = true;

  public form: UntypedFormGroup = this.fb.group(
    {
      email: ['', Validators.required],
      customer_alias: ['', Validators.required],
      password: ['', Validators.required],
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    },
    {
      validators: confirmedValidator('new_password', 'confirm_password'),
    }
  );

  constructor(
    public app: AppService,
    public fb: UntypedFormBuilder,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.app.user.subscribe({
      next: (user) => {
        const email = user.email;
        const customer_alias = this.app.customerAlias;
        this.form.patchValue({
          customer_alias,
          email,
        });
      },
      error: (error) => {
        Bugsnag.notify(error);
        this.app.navigate('login');
      },
    });
  }

  get f() {
    return this.form.controls;
  }

  public t(key: string) {
    return this.translate.get(key).toPromise();
  }

  onSubmit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.notifyInvalid();
      return;
    }

    this.app.changePassword(this.form.value).subscribe(
      () => {
        localStorage.removeItem('token');
        this.app.notify(
          this.t('auth.Password changed'),
          this.t('auth.You have been logged out')
        );
        this.app.navigate('login');
      },
      (err: Error) => {
        console.log(err);
        this.app.notifyError('auth.Error updating password', err);
      }
    );
  }

  private notifyInvalid() {
    this.app.notify(
      this.t('component.feedback.Missing or invalid'),
      this.t('component.feedback.Please fill out the required form fields.'),
      'error'
    );
  }
}
